import type { NextPage } from 'next';

import { Authenticated } from '~modules/auth/components';
import { AppLayout } from '~modules/layout';
import { SelectSupplierPage } from '~modules/suppliers/components';

const Home: NextPage = () => {
    return (
        <Authenticated>
            <AppLayout size='extraWidth' withNav={false}>
                <SelectSupplierPage />
            </AppLayout>
        </Authenticated>
    );
};

export default Home;
